<template>
    <div class="attshithe">
        <el-dialog
        :title="$t('devtable.xgbc')"
        :visible.sync="centerDialogVisible"
        width="44%"
        append-to-body
        center>


        <div class="bacitime_cont">
          <el-row class="tmf">
              <span>{{$t('devtable.bcmc')}}</span>
              <el-input v-model="name" size="small" :placeholder="$t('devtable.slbb')"></el-input>
          </el-row>
          <el-row class="tmf">
              <span>{{$t('devtable.gzsc')}}</span>
              <el-input v-model="work_hours" disabled size="small" :placeholder="$t('devtable.sbbxs')"></el-input>
          </el-row>
        </div>

        <div class="tiems">
            <div class="timesone"><el-checkbox v-model="checked1">{{$t('devtable.gzsj')}}1</el-checkbox></div>
            <div class="timeson_row">
                <!-- <span>开始时间</span> -->
                <div>
                   <el-time-picker
                        value-format="HH:mm"
                        format="HH:mm"
                        style="width:100%"
                        size="small"
                        v-model="time1_start"
                        :placeholder="$t('devtable.qssj')">
                    </el-time-picker>
                </div>

               <div>
                  <el-time-picker
                    value-format="HH:mm"
                    format="HH:mm"
                    style="width:100%"
                    size="small"
                   :placeholder="$t('devtable.jssj')"
                    v-model="time1_end"
                  >
                </el-time-picker>
               </div>
            </div>
        </div>

         <div class="tiems">
            <div class="timesone"><el-checkbox v-model="checked2">{{$t('devtable.gzsj')}}2</el-checkbox></div>
            <div class="timeson_row">
                <!-- <span>开始时间</span> -->
                <div>
                  <el-time-picker
                  :placeholder="$t('devtable.qssj')"
                  v-model="time2_start"
                  value-format="HH:mm"
                  format="HH:mm"
                  style="width:100%"
                  size="small"
                  >
                </el-time-picker>
                </div>

               <div>
                  <el-time-picker
                 :placeholder="$t('devtable.jssj')"
                  v-model="time2_end"
                  value-format="HH:mm"
                  format="HH:mm"
                  style="width:100%"
                  size="small"
                >
                </el-time-picker>
               </div>
            </div>
        </div>

        <div class="tiems">
            <div class="timesone"><el-checkbox v-model="checked3">{{$t('devtable.gzsj')}}3</el-checkbox></div>
            <div class="timeson_row">
                <!-- <span>开始时间</span> -->
                <div>
                  <el-time-picker
                  :placeholder="$t('devtable.qssj')"
                  v-model="time3_start"
                  value-format="HH:mm"
                  format="HH:mm"
                  style="width:100%"
                  size="small"
                  >
                </el-time-picker>
                </div>

               <div>
                  <el-time-picker
                 :placeholder="$t('devtable.jssj')"
                  v-model="time3_end"
                  value-format="HH:mm"
                  format="HH:mm"
                  style="width:100%"
                  size="small"
                  >
                </el-time-picker>
               </div>
            </div>
        </div>

         <div class="tiems">
            <div class="timesone"><el-checkbox v-model="checked4">{{$t('devtable.xxsj')}}1</el-checkbox></div>
            <div class="timeson_row">
                <!-- <span>开始时间</span> -->
                <div>
                  <el-time-picker
                  :placeholder="$t('devtable.qssj')"
                  v-model="rest_time1_start"
                  value-format="HH:mm"
                  format="HH:mm"
                  style="width:100%"
                  size="small"
                  >
                </el-time-picker>
                </div>

               <div>
                  <el-time-picker
                 :placeholder="$t('devtable.jssj')"
                  v-model="rest_time1_end"
                  value-format="HH:mm"
                  format="HH:mm"
                  style="width:100%"
                  size="small"
                  >
                </el-time-picker>
               </div>
            </div>
        </div>
         <div class="tiems">
            <div class="timesone"><el-checkbox v-model="checked5">{{$t('devtable.xxsj')}}2</el-checkbox></div>
            <div class="timeson_row">
                <!-- <span>开始时间</span> -->
                <div>
                  <el-time-picker
                  :placeholder="$t('devtable.qssj')"
                  v-model="rest_time2_start"
                  value-format="HH:mm"
                  format="HH:mm"
                  style="width:100%"
                  size="small"
                  >
                </el-time-picker>
                </div>

               <div>
                  <el-time-picker
                 :placeholder="$t('devtable.jssj')"
                  v-model="rest_time2_end"
                  value-format="HH:mm"
                  format="HH:mm"
                  style="width:100%"
                  size="small"
                  >
                </el-time-picker>
               </div>
            </div>
        </div>
         <div class="tiems">
            <div class="timesone"><el-checkbox v-model="checked6">{{$t('devtable.xxsj')}}3</el-checkbox></div>
            <div class="timeson_row">
                <!-- <span>开始时间</span> -->
                <div>
                  <el-time-picker
                  :placeholder="$t('devtable.qssj')"
                  v-model="rest_time3_start"
                  value-format="HH:mm"
                  format="HH:mm"
                  style="width:100%"
                  size="small"
                  >
                </el-time-picker>
                </div>

               <div>
                  <el-time-picker
                 :placeholder="$t('devtable.jssj')"
                  v-model="rest_time3_end"
                  value-format="HH:mm"
                  format="HH:mm"
                  style="width:100%"
                  size="small"
                  >
                </el-time-picker>
               </div>
            </div>
        </div>


        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handAttenshif">{{$t('tablename.qd')}}</el-button>
        </span>
        </el-dialog>
    </div>
</template>
<script>
import { ChangeHourMinutestr,clearzero,twofour } from '@/utils/index' 
export default {
    data() {
      return {
        Attenshi2list:{},
        centerDialogVisible: false,
        checked1: false,
        checked2: false,
        checked3: false,
        checked4: false,
        checked5: false,
        checked6: false,
        startTime: '',
        endTime: '',
        /**参数 */
        name:'',
        times:'',
        work_hours:'', //工作时长
        time1_start:'',
        time1_end:'',
        time2_start:'',
        time2_end:'',
        time3_start:'',
        time3_end:'',
        work_hours:'',
        rest_times:'',
        rest_time1_start:'',
        rest_time1_end:'',
        rest_time2_start:'',
        rest_time2_end:'',
        rest_time3_start:'',
        rest_time3_end:'',

        checkedlist1:[],
        checkedlist2:[],
        parntlistv:{},
        UserInfo:''
      };
    },
    watch:{
      'checked1':function(nval,oval){
          if(nval==true){
            this.checkedlist1.push(true)
          }else{
            this.checkedlist1.splice(0,1)
            this.time1_start=""
            this.time1_end=""
          }
          
        },
      'checked2':function(nval,oval){
        if(nval==true){
          this.checkedlist1.push(true)
        }else{
          this.checkedlist1.splice(0,1)
          this.time2_start=""
          this.time2_end=""
        }
      },
      'checked3':function(nval,oval){
        if(nval==true){
          this.checkedlist1.push(true)
        }else{
          this.checkedlist1.splice(0,1)
          this.time3_start=""
          this.time3_end=""
        }
      },
      'checked4':function(nval,oval){
          if(nval==true){
            this.checkedlist2.push(true)
          }else{
            this.checkedlist2.splice(0,1)
            this.rest_time1_start=""
            this.rest_time1_end=""
          }
          
        },
      'checked5':function(nval,oval){
        if(nval==true){
          this.checkedlist2.push(true)
        }else{
          this.checkedlist2.splice(0,1)
          this.rest_time2_start=""
          this.rest_time2_end=""
        }
      },
      'checked6':function(nval,oval){
        if(nval==true){
          this.checkedlist2.push(true)
        }else{
          this.checkedlist2.splice(0,1)
          this.rest_time3_start=""
          this.rest_time3_end=""
        }
      }
    },
   mounted(){
     this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
      //  this.getAttenshi2()
   },
    methods:{

        getAttenshi2(v){
          this.parntlistv= v
            let attshit2data={
                id:v.id,
                method:'att.order.get',
            }
            let data ={}
            this.$serve(attshit2data).then(res=>{
                if(res.data.code==0){
                  data=res.data.data
                }
                if(data.time1_start.substring(0,2)!="00" || data.time1_end.substring(0,2) !="00"){
                  this.checked1=true
                }else{
                  this.checked1=false
                  data.time1_start=""
                  data.time1_end=""
                }
                if(data.time2_start.substring(0,2)!="00" || data.time2_end.substring(0,2) !="00"){
                  this.checked2=true
                }else{
                  this.checked2=false
                  data.time2_start=""
                  data.time2_end=""
                }
                if(data.time3_start.substring(0,2)!="00" || data.time3_end.substring(0,2) !="00"){
                  this.checked3=true
                }else{
                  this.checked3=false
                  data.time3_start=""
                  data.time3_end=""
                }
                if(data.rest_time1_start.substring(0,2)!="00" || data.rest_time1_end.substring(0,2)!="00"){
                  this.checked4=true
                }else{
                  this.checked4=false
                  data.rest_time1_start=""
                  data.rest_time1_end=""
                }
                if(data.rest_time2_start.substring(0,2)!="00" || data.rest_time2_end.substring(0,2)!="00"){
                  this.checked5=true
                  
                }else{
                  this.checked5=false
                  data.rest_time2_start=""
                  data.rest_time2_end=""
                }
                if(data.rest_time3_start.substring(0,2)!="00" || data.rest_time3_end.substring(0,2)!="00"){
                  this.checked6=true
                }else{
                  this.checked6=false
                  data.rest_time3_start=""
                  data.rest_time3_end=""
                }
                this.name = data.name
                this.times = data.times,
                this.work_hours=data.work_hours
                this.time1_start=data.time1_start
                this.time1_end=data.time1_end
                this.time2_start=data.time2_start
                this.time2_end=data.time2_end
                this.time3_start=data.time3_start
                this.time3_end=data.time3_end
                this.rest_times=data.rest_times
                this.rest_time1_start=data.rest_time1_start
                this.rest_time1_end=data.rest_time1_end
                this.rest_time2_start=data.rest_time2_start
                this.rest_time2_end=data.rest_time2_end
                this.rest_time3_start=data.rest_time3_start
                this.rest_time3_end=data.rest_time3_end
            })
           
            
        },


      handAttenshif(){
        let time1
        let time2
        let time3
        if(this.checkedlist1){
          this.times = this.checkedlist1.length*2
        }
        if(this.checkedlist2){
          this.rest_times = this.checkedlist2.length
        }
        if(this.checked1){
          let  time1_1 = Number(twofour(this.time1_end.substring(0,2))) + Number(ChangeHourMinutestr(clearzero(this.time1_end.substring(3,5))) ) 
          let time1_2 = Number(twofour(this.time1_start.substring(0,2))) +Number(ChangeHourMinutestr(clearzero(this.time1_start.substring(3,5))) ) 
          time1  = Number(time1_1) - Number(time1_2)
        }
         
        if(this.checked2){
            let  time2_1 = Number(twofour(this.time2_end.substring(0,2)))  + Number(ChangeHourMinutestr(clearzero(this.time2_end.substring(3,5))) ) 
            let time2_2 = Number(twofour(this.time2_start.substring(0,2))) +Number(ChangeHourMinutestr(clearzero(this.time2_start.substring(3,5))) ) 
            time2  = Number(time2_1) - Number(time2_2)
            // console.log(time2)
        }
        if(this.checked3){
          let  time3_1 = Number(twofour(this.time3_end.substring(0,2)))  + Number(ChangeHourMinutestr(clearzero(this.time3_end.substring(3,5))) ) 
          let time3_2 = Number(twofour(this.time3_start.substring(0,2))) +Number(ChangeHourMinutestr(clearzero(this.time3_start.substring(3,5))) ) 
          time3  = Number(time3_1) - Number(time3_2)
          // console.log(time3)
        }
        this.work_hours = (Number(time1?time1:0) + Number(time2?time2:0)+ Number(time3?time3:0)).toFixed(1)

        let data={
          id:this.parntlistv.id,
          method:'att.order.edit',
          agent_id:this.UserInfo.agent_id,
          name:this.name,
          times:this.times,
          work_hours:this.work_hours, //工作时长
          time1_start:this.time1_start,
          time1_end:this.time1_end,
          time2_start:this.time2_start,
          time2_end:this.time2_end,
          time3_start:this.time3_start,
          time3_end:this.time3_end,
          rest_times:this.rest_times,
          rest_time1_start:this.rest_time1_start,
          rest_time1_end:this.rest_time1_end,
          rest_time2_start:this.rest_time2_start,
          rest_time2_end:this.rest_time2_end,
          rest_time3_start:this.rest_time3_start,
          rest_time3_end:this.rest_time3_end,
        }
        this.$serve(data).then(res=>{
          if(res.data.code==0){
              this.$message({
                  message: this.$t('devtable.xgcg'),
                  type: 'success'
              });
              this.$parent.getAttenshiflist()
          }else{
              this.$message({
                  message: this.$t('devtable.xgcg'),
                  type: 'error'
              });
          }
        })
        this.centerDialogVisible = false
      }
    }
}
</script>
<style lang="less" scoped>
.bacitime_cont{
  display: flex;
  .tmf{
    display: flex;
    flex: 1;
    margin-right: 20px;
    margin-left: 20px;
    span{
      flex: 3;
      line-height: 40px;
    }
    .el-input{
      flex: 5;
    }
  }
}
.tiems{
  margin: 20px;
  border: 1px solid #ccc;
  position: relative;
  padding: 10px;
  height: 50px;
  .timesone{
    position: absolute;
    top: -10px;
    left: 20px;
    width: 100px;
    height: 30px;
    padding-left: 10px;
    background-color: #fff;
    
  }
}
.timeson_row{
  margin-top: 8px;
    display: flex;
    justify-content: space-around;
  }
</style>