<template>
    <div class="attshiftcont">
        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <Tabheader @handRegister="handRegister" @handFromshow="handFromshow" :titlename="titlename"></Tabheader>
        </div>
        
        <div class="attheadfrom" v-show="flag">
            <el-form size="small" :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item :label="$t('devtable.mc')">
                    <el-input v-model="formInline.name" :placeholder="$t('devtable.mc')"></el-input>
                </el-form-item>
               
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">{{this.$t('devtable.cx')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
        <Tables @handlpages="handlpages" @handlerow="handlerow" @handleSizeChange="handleSizeChange" :tableLabel="tableLabel" :tableData="tableData" :flags="flags" :configs="configs" :operation="operation"></Tables>
        <Attshitdilg ref="attshitdilg"></Attshitdilg>
        <Cleadr ref="cleadr" @clearhand="clearhand" :data="clearldata"></Cleadr>
        <Attshit2 ref="attshit2"></Attshit2>
    </div>
</template>
<script>
import Tables from '@/components/table'
import Tabheader from './components/tabheader'
import Attshitdilg from './components/attenshiftdiolg'
import Attshit2 from './components/attenshif2'
import Cleadr from './components/cleadr'
export default {
    components:{
        Tables,
        Tabheader,
        Attshitdilg,
        Cleadr,
        Attshit2
    },
    data(){
        return{
            clearldata:{},
            flag:false,
            value1:'',
            formInline: {
                name: '',
            },
            
            
            tableData: [
            ],
            flags: {
                show: false, // 多选是否显示
                ishow: false // 序号是否显示
            },
            // 分页数据
            configs: {
                page_index:1,
                pagesize: 10,
                total: 200,
                loading: true // 加载状态
            },
            
            UserInfo:''
        }
    },
    computed:{
         titlename:function(){
             return {
                name:this.$t('tablename.zj'),
                placeholdername:this.$t('devtable.qdjcx')
            }
         },
        operation:function(){
            return{
                width:'200px',
                data:[
                {
                    name: this.$t('devtable.xg'),
                    type: 'primary'
                  },
                  {
                    name: this.$t('tablename.sc'),
                    type: 'danger'
                  },
                ]
            }
        } ,
        tableLabel:function(){

         return[
                {
                    prop: 'name',
                    label: this.$t('devtable.mc'),
                    align: 'center'
                },
                {
                    prop: 'times',
                    label: this.$t('devtable.dklx'),
                    align: 'center'
                },
                {
                    prop: 'timeslot',
                    label: this.$t('devtable.sbsjd'),
                    align: 'center'
                },
                
            ]
        }
    },
    watch:{
        'formInline.name':function(naval,oldval){
            if(naval!=oldval){
                this.getAttenshiflist()
            }
        }
    },
    mounted(){
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getAttenshiflist()
    },
    methods:{

        getAttenshiflist(v,name){
           let data={
                method:'att.orders.get',
                agent_id:this.UserInfo.agent_id,
                name:name?name:'',
                page_index:v?v:this.configs.page_index,
                page_size:this.configs.pagesize
           }
           this.$serve(data).then(res=>{
               console.log(res)
               this.configs.total =res.data.data.recordCount
               if(res.data.code ==0){
                    let data =  res.data.data.records
                    data.forEach(element => {
                        if(element.times){
                            element.times = this.$t('devtable.mt')+` ${element.times} ` + this.$t('devtable.cs')
                        }
                        
                        if(element.rest_time1_end){
                            element.timeslot = `${element.time1_start} - ${element.time1_end}`+ "     " +`${element.time2_start} - ${element.time2_end}`
                        }
                    });
                    this.tableData = data
                    this.configs.loading =false
               }
           })
        },

        onSubmit() {
            this.getAttenshiflist('',this.formInline.name)
        },
        // 修改删除
        handlerow(v,name){
            console.log(v)
            if(name=="修改" || name=="Modify"){
                this.$refs.attshit2.getAttenshi2(v)
                this.$refs.attshit2.centerDialogVisible = true
            }else{
                this.clearldata={
                    method:'att.order.delete',
                    id:v.id
                }
                this.$refs.cleadr.dialogVisible = true
            }
        },
        // 删除后回调列表接口
        clearhand(){
            this.getAttenshiflist()
        },
        handRegister(){
            this.$refs.attshitdilg.centerDialogVisible=true
        },  
        handFromshow(){
            this.flag = !this.flag
        },
        //分页
        handlpages(v){
            this.configs.page_index=v
            this.getAttenshiflist()
        },
        handleSizeChange(v){
            this.configs.page_index=1
            this.configs.pagesize=v
            this.getAttenshiflist()
        }
    }
}
</script>
<style lang="less" scoped>
.attshiftcont{
    padding: 20px;
    background-color: #fff;
    .attheadfrom{
        padding: 20px;
        margin-top: 10px;
    }
}
</style>